import React from "react";
import { useState } from "react";
import Work from "./Work";
function WorksMain() {
  const work = [
    {
      name: "samskrita Bharati",
      line1: "",
      line2: "",
      line3: "",
      line4: "",
    },
    {
      name: "Petro-Canada",
      line1: "",
      line2: "",
    },
  ];
  const SamskritaBharati = ['Develop and maintain web applications using HTML, CSS, and JavaScript that meet user requirements and are scalable, robust, and maintainable.', 'Design and implement user interfaces using modern front-end frameworks such as ReactJS to create engaging and responsive user experiences.', 'Utilize Firebase as a cloud-based backend solution for building real-time applications and databases.', 'Participate in code reviews, testing, and debugging to ensure high-quality code that meets industry best practices and standards.'];
 const javaDev = ['Develop and maintain RESTful APIs using Spring Boot that provide efficient and secure communication between the frontend and backend.', 'Design and implement user interfaces using React to create engaging and responsive user experiences.', 'Participate in code reviews, testing, and debugging to ensure high-quality code that meets industry best practices and standards.', 'Work with relational databases such as MySQL and PostgreSQL to design and implement efficient and secure data storage solutions.', 'Collaborate with other developers, designers, and project managers to ensure timely and successful project completion.']
  const chetan = [
    "Provided desk side technical support to the employees for touchscreens, smartboards, networks, tablets, and servers.",
    "Gathered advanced knowledge of networking concepts like LAN, WAN, PAN.",
    "Assisted with IT teams with project management and network security.",
  ];
  const [visibleWork, setVisibleWork] = useState([]);

  return (
    <div>
      <Work
        position={"Open-Source-Developer "}
        workPara={SamskritaBharati}
        title={"Samskrita Bharati"}
      />

<Work
        position={"Java Developer"}
        workPara={javaDev}
        title={"MoonRoof Consulting"}
      />
    </div>
  );
}

export default WorksMain;
