import React from "react";
import FeaturedProject from "./FeaturedProject";
import ForumEdited from "../Images/ForumEdited.png";
import ReviewEdited from "../Images/ReviewEdited.png";
import AppointEdited from "../Images/AppointEdited.png";
import dash from '../Images/dash.jpg'
import book from '../Images/book.jpg'
import job from '../Images/job.jpg'
import product from '../Images/product.jpg'
import RandomShape from "./RandomShape";
import Tag from "./Tag";
function FeaturedProjectMain() {
  const descReview =
    "Restaurant review system in which we can give reviews to resturant in the database. I fully focused on keeping the UI/UX efficient and good looking by applying the rules of basic colour theory and typography.";
  const reviewTech = ["Java", "Springboot", "Javascript"];
  const urlGR =
    "https://github.com/ConfidentProgrammer/Restaurant-Review-System";
  const urlLR =
    "https://drive.google.com/file/d/1tFmWZlN5htRN6vL8lz1CmMJcHJfnnJHN/view?usp=sharing";

  const descAppoint =
    "This is the fully functional pet appointment system in which we can book, modify, delete and add our the appointment, all the data is coming from the JSON file";
  const appointTech = ["react.js", "HTML5", "Javascript", "CSS3"];
  const urlGA = "https://github.com/ConfidentProgrammer/Pet-Appointment-System";
  const urlLA =
    "https://drive.google.com/file/d/1QBtYChyl0hfwS9tVzqb7YE-ShhjX0yRP/view?usp=sharing";

  const descForum =
    "This is the fully functionalized employee forum, similar to reddit but simpler that it. Employee can write, edit threads and also comments on other people threads. Employee needs to login or register in order to view all the threads";
  const forumTech = ["Java", "Springboot", "Javascript", "UI/UX"];
  const urlGF = "https://github.com/ConfidentProgrammer/Employee-Forum";
  const urlLF =
    "https://drive.google.com/file/d/1B4KATPlrifh1di0iJErCL4Q_jhMuB5_a/view?usp=sharing";



    //new changes 
    const descJobListing = "Job Listing is frontend mentor challenge that involves following of latest UI trends and creation of filtering feature that helps job seekers to filter jobs based on categories"
    const JobListingTech = ["ReactJs", "Redux", "Netlify"]
    const urlGitJob = "https://github.com/ConfidentProgrammer/Job-listing-React";
    const urlLiveJob = "https://job-listing-mentorio.netlify.app"

    //bookmark
    const descBookmark = "This frontend mentor challenge tested my layout skills. This project takes help of CSS grid and CSS flexbox for laying out the components in react"
    const urlGitBook = "https://github.com/ConfidentProgrammer/Bookmark-page-react"
    const urlLiveBook = "https://bookmark-mentrio.netlify.app/"

    //social Dashboard
    const descDash = "Social Media Dashboard with latest UI trends and perfectly implemented grid. This project also has color theme switcher"
    const urlGitDash =  "https://github.com/ConfidentProgrammer/social-dashboard"
    const urlLiveDash = "https://gorgeous-churros-1a9889.netlify.app/"

    //productPage
    const descProduct = "E-Commerce product page with built in cart functionality can help building ecommerce application quickly with this component/";
    const urlGitProd = "https://github.com/ConfidentProgrammer/Product-Page-React"
    const urlLiveProd = "https://dancing-biscochitos-0e37ae.netlify.app/"


  return (
    <div>
      <Tag name={"other Project"} />
   
      <RandomShape L={700} T={2500} />
      {/* <FeaturedProject
        image={ForumEdited}
        desc={descForum}
        tech={forumTech}
        name={"Employee Forum"}
        urlL={urlGF}
        urlG={urlLF}
      /> */}
     

<FeaturedProject
        image={job}
        desc={descJobListing}
        tech={JobListingTech}
        name={"Job Listing"}
        urlL={urlGitJob}
        urlG={urlLiveJob}
      />
      <FeaturedProject
        image={book}
        desc={descBookmark}
        tech={JobListingTech}
        name={"Bookmark Manager Page"}
        urlL={urlGitBook}
        urlG={urlLiveBook}
      />
      <FeaturedProject
        image={product}
        desc={descProduct}
        tech={JobListingTech}
        name={"E-Commerce Page"}
        urlL={urlGitProd}
        urlG={urlLiveProd}
      />
      <FeaturedProject
        image={dash}
        desc={descJobListing}
        tech={JobListingTech}
        name={"Social Media Dashboard"}
        urlL={urlGitDash}
        urlG={urlLiveDash}
      />
    </div>
  );
}

export default FeaturedProjectMain;
